<template>
  <div id="create-store-application">
    <v-card elevation="0">
      <v-card-title>
        {{ $t("__createstoreapplication_title") }}
        <br v-if="isMobile" />
        <template v-else>
          {{ formID }}
        </template>
        <v-chip class="ma-2" :color="statusColor" dark>
          {{ formStatus }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <table class="data-table">
          <template v-for="(item, i) in items">
            <template
              v-if="
                !form ||
                  item.storeOwnerTypes.findIndex(
                    t => t === form['store_owner_type']
                  ) !== -1
              "
            >
              <tr :key="`data-${i}`">
                <th>{{ item.text }}</th>
                <td v-if="!isMobile" class="td-height">
                  <v-row align="center">
                    <v-col cols="10">
                      <template v-if="form">
                        {{ form[item.key] }}
                        <v-icon
                          v-if="form[item.key] && item.action"
                          color="primary"
                          @click="item.action"
                        >
                          mdi-open-in-new
                        </v-icon>
                      </template>
                    </v-col>
                    <v-col cols="2" v-if="form">
                      <v-checkbox
                        color="error"
                        off-icon="mdi-check-bold"
                        on-icon="mdi-close-thick"
                        :label="$t('__application_field_status')"
                        v-if="
                          item.replyStatus &&
                            formStatus === $t('__application_status_submitted')
                        "
                        @change="replyTextForm(item.key)"
                        v-model="textFieldsStatus[item.key]"
                      />
                      <template
                        v-else-if="item.replyStatus && form.reply_fields_status"
                      >
                        <v-icon
                          :color="
                            form['reply_fields_status'][item.key] === 'rejected'
                              ? 'error'
                              : 'success'
                          "
                        >
                          {{
                            form["reply_fields_status"][item.key] === "rejected"
                              ? "mdi-close-thick"
                              : "mdi-check-bold"
                          }}
                        </v-icon>
                        <span>{{
                          form["reply_fields_status"][item.key] === "rejected"
                            ? $t("__application_field_reject")
                            : $t("__application_field_passed")
                        }}</span>
                      </template>
                    </v-col>
                  </v-row>
                </td>
              </tr>
              <tr v-if="isMobile" :key="`data-${i}-1`">
                <td class="data-table__subrow_td td-height">
                  <v-row align="center">
                    <v-col cols="8">
                      <template v-if="form">
                        {{ form[item.key] }}
                        <v-icon
                          v-if="form[item.key] && item.action"
                          color="primary"
                          @click="item.action"
                        >
                          mdi-open-in-new
                        </v-icon>
                      </template>
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox
                        color="error"
                        off-icon="mdi-check-bold"
                        on-icon="mdi-close-thick"
                        :label="$t('__application_field_status')"
                        v-if="
                          item.replyStatus &&
                            formStatus === $t('__application_status_submitted')
                        "
                        @click="replyTextForm(item.key)"
                        v-model="textFieldsStatus[item.key]"
                      />
                      <template
                        v-else-if="item.replyStatus && form.reply_fields_status"
                      >
                        <v-icon
                          :color="
                            form['reply_fields_status'][item.key] === 'rejected'
                              ? 'error'
                              : 'success'
                          "
                        >
                          {{
                            form["reply_fields_status"][item.key] === "rejected"
                              ? "mdi-close-thick"
                              : "mdi-check-bold"
                          }}
                        </v-icon>
                        <span>{{
                          form["reply_fields_status"][item.key] === "rejected"
                            ? $t("__application_field_reject")
                            : $t("__application_field_passed")
                        }}</span>
                      </template>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </template>
          <template v-for="(item, i) in photos">
            <template
              v-if="
                !form ||
                  item.storeOwnerTypes.findIndex(
                    t => t === form['store_owner_type']
                  ) !== -1
              "
            >
              <template v-if="!!form && form['photo_urls']">
                <tr
                  v-if="form && form['photo_urls'][item.name]"
                  :key="`photos-${i}`"
                >
                  <td colspan="2">
                    <v-row align="center">
                      <v-col :cols="isMobile ? 8 : 10">
                        <v-expansion-panels accordion flat class="mr-3">
                          <v-expansion-panel>
                            <v-expansion-panel-header>{{
                              $t(`__${item.name}_photo`)
                            }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <div
                                class="d-flex flex-wrap justify-center"
                                v-if="form"
                              >
                                <v-card
                                  class="d-flex justify-center align-center ma-1"
                                  v-for="photoIndex in item.quantity"
                                  :key="`photos-${i}-${photoIndex}`"
                                  outlined
                                  width="320"
                                  height="240"
                                >
                                  <v-img
                                    contain
                                    width="100%"
                                    height="100%"
                                    :src="
                                      form['photo_urls'][item.name][
                                        photoIndex - 1
                                      ]
                                    "
                                  />
                                  <v-btn
                                    class="mb-8"
                                    fab
                                    small
                                    color="primary"
                                    absolute
                                    bottom
                                    right
                                    @click="
                                      toPhotoURL(item.name, photoIndex - 1)
                                    "
                                  >
                                    <v-icon dark>mdi-open-in-new</v-icon>
                                  </v-btn>
                                </v-card>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col :cols="isMobile ? 4 : 2">
                        <v-checkbox
                          color="error"
                          off-icon="mdi-check-bold"
                          on-icon="mdi-close-thick"
                          :label="$t('__application_field_status')"
                          @click="replyPhotoForm(item.name)"
                          v-if="
                            formStatus === $t('__application_status_submitted')
                          "
                          v-model="photoFiledsStatus[item.name]"
                        />
                        <template v-else-if="form.reply_fields_status">
                          <v-icon
                            :color="
                              form['reply_fields_status'][item.name] ===
                              'rejected'
                                ? 'error'
                                : 'success'
                            "
                          >
                            {{
                              form["reply_fields_status"][item.name] ===
                              "rejected"
                                ? "mdi-close-thick"
                                : "mdi-check-bold"
                            }}
                          </v-icon>
                          <span>{{
                            form["reply_fields_status"][item.name] ===
                            "rejected"
                              ? $t("__application_field_reject")
                              : $t("__application_field_passed")
                          }}</span>
                        </template>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </table>
      </v-card-text>
    </v-card>
    <template v-if="form">
      <v-btn
        color="primary"
        absolute
        right
        top
        @click="toReply"
        v-if="formStatus === this.$t('__application_status_submitted')"
      >
        {{ $t("__reply") }}
      </v-btn>
    </template>
    <v-dialog max-width="600px" v-model="replyDialogShow">
      <v-card>
        <v-card-title>{{
          $t("__reply_create_store_application")
        }}</v-card-title>
        <v-card-text>
          <v-radio-group v-model="replyData.accept">
            <v-radio color="success" :value="true"
              ><template v-slot:label
                ><strong class="success--text">{{
                  $t("__accept")
                }}</strong></template
              ></v-radio
            >
            <v-radio color="error" :value="false"
              ><template v-slot:label
                ><strong class="error--text">{{
                  $t("__reject")
                }}</strong></template
              ></v-radio
            >
          </v-radio-group>
          <v-textarea
            class="replyDescription"
            outlined
            :label="$t('__description')"
            v-model="replyData.descriptionString"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="toReplyCancel">{{ $t("__cancel") }}</v-btn>
          <v-btn color="primary" text @click="toReplyConfirm">{{
            $t("__confirm")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { adminAPI } from "@/plugins/service";
import { format } from "date-fns";

export default {
  name: "CreateStoreApplication",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    }),
    statusColor() {
      let color;
      if (this.form) {
        switch (this.form["status"]) {
          case "unsubmitted":
            color = "grey";
            break;
          case "submitted":
            color = "primary";
            break;
          case "accepted":
            color = "success";
            break;
          case "rejected":
            color = "error";
            break;
          default:
            break;
        }
      }
      return color;
    }
  },
  data() {
    return {
      formID: "",
      form: null,
      formStatus: "",
      items: [
        {
          text: this.$t("__user_id"),
          key: "user_id",
          storeOwnerTypes: ["company", "personal"],
          action: this.toFormUser
        },
        {
          text: this.$t("__store_name"),
          key: "store_name",
          storeOwnerTypes: ["company", "personal"],
          replyStatus: true
        },
        {
          text: this.$t("__store_industry_type"),
          key: "store_industry_type",
          storeOwnerTypes: ["company", "personal"],
          replyStatus: true
        },
        {
          text: this.$t("__owner_name"),
          key: "owner_name",
          storeOwnerTypes: ["company", "personal"],
          replyStatus: true
        },
        {
          text: this.$t("__owner_phone_number"),
          key: "owner_phone_number",
          storeOwnerTypes: ["company", "personal"],
          replyStatus: true
        },
        {
          text: this.$t("__owner_email"),
          key: "owner_email",
          storeOwnerTypes: ["company", "personal"],
          replyStatus: true
        },
        {
          text: this.$t("__store_owner_type"),
          key: "store_owner_type_t",
          storeOwnerTypes: ["company", "personal"]
        },
        {
          text: this.$t("__tax_id_number"),
          key: "tax_id_number",
          storeOwnerTypes: ["company"],
          replyStatus: true
        },
        {
          text: this.$t("__store_phone_number"),
          key: "store_phone_number",
          storeOwnerTypes: ["company", "personal"],
          replyStatus: true
        },
        {
          text: this.$t("__store_address"),
          key: "store_address",
          storeOwnerTypes: ["company", "personal"],
          action: this.toFormStoreAddress,
          replyStatus: true
        },
        {
          text: this.$t("__store_bank_code"),
          key: "store_bank_code",
          storeOwnerTypes: ["company", "personal"],
          replyStatus: true
        },
        {
          text: this.$t("__store_bank_account"),
          key: "store_bank_account",
          storeOwnerTypes: ["company", "personal"],
          replyStatus: true
        },
        {
          text: this.$t("__store_website"),
          key: "store_website",
          storeOwnerTypes: ["company", "personal"],
          action: this.toFormStoreWebsite,
          replyStatus: true
        },
        {
          text: this.$t("__telegram_bot_token"),
          key: "telegram_bot_token",
          storeOwnerTypes: ["company", "personal"],
          action: this.toFormTelegramBotToken,
          replyStatus: true
        },
        {
          text: this.$t("__apply_time"),
          key: "create_time_f",
          storeOwnerTypes: ["company", "personal"]
        },
        {
          text: this.$t("__update_time"),
          key: "update_time_f",
          storeOwnerTypes: ["company", "personal"]
        },
        {
          text: this.$t("__submit_time"),
          key: "submit_time_f",
          storeOwnerTypes: ["company", "personal"]
        },
        {
          text: this.$t("__reply_time"),
          key: "reply_time_f",
          storeOwnerTypes: ["company", "personal"]
        }
      ],
      photos: [
        {
          name: "bank_passbook",
          quantity: 1,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "location_with_owner",
          quantity: 3,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "store_sign",
          quantity: 3,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "environment_with_owner",
          quantity: 3,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "environment",
          quantity: 2,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "registration_certificate",
          quantity: 2,
          storeOwnerTypes: ["company"]
        },
        {
          name: "owner_id_card",
          quantity: 2,
          storeOwnerTypes: ["company", "personal"]
        },
        {
          name: "owner_id_card_with_owner",
          quantity: 2,
          storeOwnerTypes: ["personal"]
        }
      ],
      replyDialogShow: false,
      replyData: {
        accept: false,
        description: [],
        descriptionString: "",
        fieldsStatus: {}
      },
      textFieldsStatus: {},
      photoFiledsStatus: {}
    };
  },
  methods: {
    formatAddress(address) {
      let format = `${address.postal_code}${address.city}${address.area}${
        address.road
      }${address.lane}${address.lane ? this.$t("__store_address_lane") : ""}
      ${address.alley}${address.alley ? this.$t("__store_address_alley") : ""}
       ${address.number}${this.$t("__store_address_number")}
      ${
        address["sub_number"]
          ? this.$t("__store_address_sub_number") + address["sub_number"]
          : ""
      }
      ${address.floor}${address.floor ? this.$t("__store_address_floor") : ""}${
        address.room
      }${address.room ? this.$t("__store_address_room") : ""}`;
      return format;
    },
    loadCreateStoreApplicationForm() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("admin/getUserIDToken")
        .then(idToken => {
          return adminAPI.getCreateStoreApplicationForm(idToken, this.formID);
        })
        .then(result => {
          this.$store.dispatch("setIsLoading", false);
          this.form = result["create_store_application_form"];
          this.form["store_owner_type_t"] = this.$t(
            `__store_owner_type_${this.form["store_owner_type"]}`
          );
          this.formStatus = this.$t(
            `__application_status_${this.form["status"]}`
          );
          if (this.form["store_industry_type"]) {
            this.form["store_industry_type"] = this.$t(
              `__store_industry_type_${this.form["store_industry_type"]}`
            );
          }
          const addressDetail = this.form["store_address_detail"];
          if (addressDetail) {
            this.form["store_address"] = this.formatAddress(addressDetail);
          }
          this.form["create_time_f"] = this.formatTime(
            this.form["create_time"]
          );
          this.form["update_time_f"] = this.formatTime(
            this.form["update_time"]
          );
          this.form["submit_time_f"] = this.formatTime(
            this.form["submit_time"]
          );
          this.form["reply_time_f"] = this.formatTime(this.form["reply_time"]);
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
          this.$router.back();
        });
    },
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd HH:mm:ss");
    },
    toFormUser() {
      const userID = this.form["user_id"];
      this.$router.push({ name: "UserInfo", params: { id: userID } });
    },
    toFormStoreAddress() {
      const storeAddress = this.form["store_address"];
      const url = `https://www.google.com/maps?q=${storeAddress}`;
      window.open(url);
    },
    toFormStoreWebsite() {
      const storeWebsite = this.form["store_website"];
      window.open(storeWebsite);
    },
    toFormTelegramBotToken() {
      const telegramBotToken = this.form["telegram_bot_token"];
      const url = `https://api.telegram.org/bot${telegramBotToken}/getMe`;
      window.open(url);
    },
    toPhotoURL(name, index) {
      const photoURL = this.form["photo_urls"][name][index];
      window.open(photoURL);
    },
    replyTextForm(key) {
      let status = "passed";
      const found = this.replyData["description"].findIndex(i => i.key === key);
      if (this.textFieldsStatus[key]) {
        status = "rejected";
        this.replyData["fieldsStatus"][key] = status;
        if (found !== 0) {
          this.replyData["description"].push({
            key,
            text: this.$t(`__${key}`) + this.$t("__application_field_reject")
          });
        }
      } else {
        this.replyData["description"].splice(found, 1);
      }
      const data = this.replyData["description"].map((item, index) => {
        return `${index + 1}.${item.text}`;
      });
      this.replyData["descriptionString"] = data.join(" ");
    },
    replyPhotoForm(key) {
      let status = "passed";
      const found = this.replyData["description"].findIndex(i => i.key === key);
      if (this.photoFiledsStatus[key]) {
        status = "rejected";
        this.replyData["fieldsStatus"][key] = status;
        if (found !== 0) {
          this.replyData["description"].push({
            key,
            text:
              this.$t(`__${key}_photo`) + this.$t("__application_field_reject")
          });
        }
      } else {
        this.replyData["description"].splice(found, 1);
      }
      const data = this.replyData["description"].map((item, index) => {
        return `${index + 1}.${item.text}`;
      });
      this.replyData["descriptionString"] = data.join(" ");
    },
    toReply() {
      this.replyData.accept = false;
      this.replyDialogShow = true;
    },
    toReplyConfirm() {
      this.replyDialogShow = false;
      this.reply();
    },
    toReplyCancel() {
      this.replyDialogShow = false;
      this.replyData.description = [];
      this.replyData.descriptionString = "";
      this.textFieldsStatus = {};
      this.photoFiledsStatus = {};
    },
    reply() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("admin/getUserIDToken")
        .then(idToken => {
          return adminAPI.replyCreateStoreApplicationForm(
            idToken,
            this.formID,
            this.replyData.accept,
            this.replyData.fieldsStatus,
            this.replyData.descriptionString
          );
        })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
          return this.loadCreateStoreApplicationForm();
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
          this.$router.back();
        });
    }
  },
  created() {
    this.formID = this.$route.params["id"];
    this.loadCreateStoreApplicationForm();
  }
};
</script>

<style lang="scss">
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-block: 1em;

  th,
  td {
    border: 1px solid silver;
    padding: 8px;
  }
  th {
    width: 1%;
    white-space: nowrap;
    text-align: left;
    color: black;
  }
  td {
    color: black;
  }
  &__subrow_td {
    padding-left: 24px !important;
  }
}
.td-height {
  height: 83px;
  line-height: 65px;
}
</style>
